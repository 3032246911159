import { Component, OnInit, Input } from '@angular/core';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../services/api-url/api-url.service';
import { Store, select } from '@ngrx/store';
import * as rootStore from '../../../app.reducer';
import { UtilityService } from '../../../services/utilities/utility.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/mergeMap';
import { StandaloneService } from '../../../services/standalone/standalone.service';
import { headerMenuID } from '../../../shared/data/menu-data';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'batp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit {

  constructor(private _req: ApirequestService,
    private _urls: ApiUrlService,
    private _utils: UtilityService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _standalone: StandaloneService,
    private _sb: SnackbarService,
    private router: Router,
    private store: Store<rootStore.State>) {

  }
  menuItems: any = [];
  isAuthenticated: boolean = false;
  userData: any = [];
  userDetails: any = [];
  showLoader: boolean = false;
  currentRouteData: any = '';
  logOutLoader: boolean = false;
  env: string = environment.env;
  isMobile: boolean = false;
  getMenuItems() {
    let data = {
      id: headerMenuID
    }
    this._req.fetchApiData(this._urls.menuItemsByNameUrl, data, true).subscribe(
      (data: any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if (resSucc != '') {
          this.menuItems = resSucc;
        }

      },
      error => {

      },
      () => {

      }
    );
  }
  navToPage(menu) {
    this._router.navigate([menu.url]);
  }

  isCurrentMenu(menu) {
    return this.currentRouteData == menu.url;
  }
  menuStatus: boolean = false;
  menuToggle(bool) {
    this.menuStatus = bool;
  }

  preferredTheme: boolean = true;
  toggleTheme() {
    let data = {
      preferredTheme: this.preferredTheme ? false : true
    };
    this._req.fetchApiData(this._urls.changeThemeUrl, data, false).subscribe(
      (data: any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if (resSucc != '') {
          this.preferredTheme = !this.preferredTheme;
        }
      },
      error => {

      },
      () => {

      }
    )
  }


  walletToggle: boolean = false;
  ngOnInit() {
    this.isMobile = this._utils.checkMobileDevice();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.currentRouteData = this._route.root.firstChild.snapshot.data['name'];
    });
  }

}
