import { Component, OnInit } from '@angular/core';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../services/api-url/api-url.service';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';

@Component({
  selector: 'batp-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.css']
})
export class AccountActivationComponent implements OnInit {

  constructor(private _req:ApirequestService,
              private _url:ApiUrlService,
              private _ls:LoginService,
              private _ar:ActivatedRoute) { }


  errMessage:string = '';
  activationStatus:boolean = true;
  accountActivation(data) {
    this._req.fetchApiData(this._url.activationUrl,data).subscribe(
      (data:any) => {
        let resSucc = data.data;
        let resErr = data.error;
        if(resSucc != '') {
          this._ls.setLoginAction(resSucc);
        }
        if(resErr != '') {
          this.activationStatus = false;
          this.errMessage = resErr['Error Description'];
        }
      },
      error => {

      },
      () => {

      }
    )
  }
  ngOnInit() {
    //https://mfowethu.hybse.com/accountActivation?id=407&activationKey=fd5d8094b407ec6efdd4ca890e29d3db
    this._ar.queryParams.subscribe(
      data => {
        if( data.id && data.activationKey) {
          this.accountActivation(data);
        }
      }
    )
  }

}
