import { Injectable } from '@angular/core';
import { BatpConfig } from '../../config/batp.config';

@Injectable()
export class ApiUrlService {

  constructor(public config: BatpConfig) {

  }

  listMenuItemUrl: string = this.config.apiURL + 'getMenuHierarchy';

  /* Login Urls */
  // loginUrl: string = this.config.apiURL + 'userLogin';
  loginUrl: string = this.config.authURL + 'userLogin';


  /* Get Menu Item */
  menuItemsByNameUrl: string = this.config.apiURL + 'menuItemsByName';

  /* User Details */
  userDetailsUrl: string = this.config.apiURL + 'userInfo';
  companyDetailsUrl: string = this.config.apiURL + 'companyDetail';

  issuerRegistrationUrl: string = this.config.authURL + 'issuerRegistration';
  userRegistrationUrl: string = this.config.authURL + 'investorRegister';


  /* User Verification */
  resendEmailUrl: string = this.config.authURL + 'resendEmail';
  resendEmailIssuerUrl: string = this.config.authURL + 'resendIssuerActivationEmail'

  /* Issuer Detail */
  issuerDetailUrl: string = this.config.apiURL + 'issuerDetail';


  /* Password & Email Change */
  changePasswordUrl: string = this.config.apiURL + 'passwordChange';
  changeEmailUrl: string = this.config.apiURL + 'emailChange';


  /* User Update Profile and Wallet */
  updateUserDetailsUrl: string = this.config.apiURL + 'updateUserDetails';
  updateFinancialDetailsUrl: string = this.config.apiURL + 'updateFinancialDetails';


  /* User Account Details */
  userAccountDetailsUrl: string = this.config.apiURL + 'userAccountDetails';
  ledgerDetailsUrl: string = this.config.apiURL + 'ledgerDetails';

  userDetailmainUrl: string = this.config.pollingURL;


  /* Google Two Factor Authentication */
  verify2faUrl: string = this.config.apiURL + 'verify2fa';
  verifyQRCodeUrl: string = this.config.apiURL + 'verifyQRCode';
  twofaEnableorNotUrl: string = this.config.apiURL + 'twofaEnableorNot';
  verify2faAtLoginUrl: string = this.config.apiURL + 'verify2faAtLogin';


  /* Get Public and Private Keys */
  getUserAPIKeysUrl: string = this.config.apiURL + 'getUserAPIKeys';

  /* Transaction History */
  nemHistoryUrl: string = this.config.apiURL + 'nemHistoryList';


  /* Active and Matched Orders */
  userOrderUrl: string = this.config.apiURL + 'userOrder';

  /* Activation and Temp Email Update */
  // activationUrl: string = this.config.apiURL + 'accountActivation';
  // tempEmailUpdateUrl: string = this.config.apiURL + 'tmpEmailUpdate';
  activationUrl: string = this.config.authURL + 'accountActivation';
  tempEmailUpdateUrl: string = this.config.authURL + 'tmpEmailUpdate';

  /* Forgot Password and Reset Password */
  // forgotPasswordUrl: string = this.config.apiURL + 'forgotPassword';
  forgotPasswordUrl: string = this.config.authURL + 'forgotPassword';
  resetPasswordUrl: string = this.config.authURL + 'resetPassword';


  /* Profile Image Update */
  updateProfileImageUrl: string = this.config.apiURL + 'updateProfileimage';

  /* Update Document & Silver, Gold Verify */
  verifySilverUrl: string = this.config.apiURL + 'verifySilver';
  goldVerifyUrl: string = this.config.apiURL + 'goldVerify';

  /* Markets */
  marketDepthUrl: string = this.config.apiURL + 'marketDepth';

  /* IBO LIST */
  iboList = this.config.apiURL + 'iBOList';

  /*Session Check */
  heartBeatUrl = this.config.apiURL + 'heartbeat';


  /* Page Content Api */
  getContentUrl: string = this.config.apiURL + 'getContent';
  getContentByCategoryUrl: string = this.config.apiURL + 'getContentByCategory';

  /* CET Time */
  cetTimeUrl: string = this.config.apiURL + 'cetTime';

  /* Contact & Support */
  supportContactUrl = this.config.apiURL + 'supportContact';

  /*KYC */
  webHooksAddUrl = `${this.config.kycURL}internal/webhook/add`;

  /**  KYC update */
  getQRcodeUrl = this.config.apiURL + 'getQRcode';
  getUserKycUrl = this.config.apiURL + 'getUserKyc';

  /**  Change Preferred Theme */
  changeThemeUrl: string = this.config.apiURL + 'changeTheme';

  /*Log Out */
  logoutUrl: string = this.config.apiURL + 'Logout';
  logoutPreviousSessionUrl: string = this.config.apiURL + 'logoutPreviousSession';
  isUserLogin: string = this.config.authURL + 'isUserLogin';

  /* Transfer from HYBSE to BEX */
  transferUrl: string = this.config.apiURL + 'transferAssets';

  /* Coupon */
  accessKeyUrl: string = this.config.authURL + 'getAccessKey';
  createCouponUrl: string = this.config.authURL + 'createCoupon';

  /* Voucher Config URLs */
  vconfigListUrl: string = this.config.apiURL + 'getVoucherConfigList';
  vconfigDetailsUrl: string = this.config.apiURL + 'getDetailedVoucherConfig';

  /* Currency exchange URLs */
  exchangeUrl: string = this.config.exchangeURL + '/rate/';
}
