import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AmChartsModule } from '@amcharts/amcharts3-angular';


import { AppComponent } from './app.component';
import { HeaderComponent } from './components/common/header/header.component';
import {HomeComponent, SafeHtmlPipe} from './components/pages/home/home.component';
// Routing Module
import { BatpRoutingModule } from './modules/routing/app.routing.module';
import { HomeTickerComponent } from './components/widgets/home-ticker/home-ticker.component';
import { RegisterSectionComponent } from './components/common/register-section/register-section.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { RegistrationComponent } from './components/pages/registration/registration.component';
import { LoaderComponent } from './components/common/loader/loader.component';
import { ApiUrlService } from './services/api-url/api-url.service';
import { ApiserviceService } from './services/apiservice/apiservice.service';
import { StorageService } from './services/localstorage/storage.service';
import { ApirequestService } from './services/apirequest/apirequest.service';
import { BatpConfig } from './config/batp.config';
import { HttpClientModule } from '@angular/common/http';
import { UtilityService } from './services/utilities/utility.service';
import { TimerService } from './services/utilities/timer/timer.service';
import { LoginComponent } from './components/pages/login/login.component';
import { LoginService } from './services/login/login.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { batpReducers } from './app.reducer';
import { MessageBoxComponent } from './components/widgets/message-box/message-box.component';
import { RegisterService } from './services/register/register.service';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap';

/* Captcha  Module */
import { NgxCaptchaModule } from 'ngx-captcha';
import { FsUploadService } from './services/fs-upload/fs-upload.service';
import { CryptoService } from './services/crypto/crypto.service';
import { LogoutService } from './services/logout/logout.service';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { SecurityComponent } from './components/pages/profile/security/security.component';
import { SettingsComponent } from './components/pages/profile/settings/settings.component';
import { VerificationComponent } from './components/pages/profile/verification/verification.component';
import { BronzeComponent } from './components/pages/profile/verification/bronze/bronze.component';
import { SilverComponent } from './components/pages/profile/verification/silver/silver.component';
import { GoldComponent } from './components/pages/profile/verification/gold/gold.component';
import { DatumService } from './services/datum/datum.service';
import { GetnamePipe } from './pipes/utils/getname.pipe';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatCheckboxModule, MatRadioModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';


import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';


import { PaginationComponent } from './components/widgets/pagination/pagination.component';
import { DialogBoxComponent } from './components/material/dialog-box/dialog-box.component';
import { ModalBoxComponent } from './components/widgets/modal-box/modal-box.component';
import { PageBannerComponent } from './components/common/page-banner/page-banner.component';
import { AboutUsComponent } from './components/content-page/about-us/about-us.component';
import { PressCenterComponent } from './components/content-page/press-center/press-center.component';
import { ContactFormComponent } from './components/common/contact-form/contact-form.component';
import { PressImageComponent } from './components/content-page/press-center/press-image/press-image.component';
import { PressContentComponent } from './components/content-page/press-center/press-content/press-content.component';
import { DLogoComponent } from './components/content-page/press-center/d-logo/d-logo.component';
import { ContactUsComponent } from './components/content-page/contact-us/contact-us.component';
import { LegalComponent } from './components/content-page/legal/legal.component';
import { LegalDetailContentComponent } from './components/content-page/legal/legal-detail-content/legal-detail-content.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { Error404Component } from './components/error/error404/error404.component';
import { AuthenticationGuard } from './gaurds/auth/auth.guard';
import { AuthenticationOffGuard } from './gaurds/auth/auth-off-guard';
import { AccountActivationComponent } from './components/pages/account-activation/account-activation.component';
import { ChangeEmailComponent } from './components/pages/change-email/change-email.component';
import { VideoDialogComponent } from './components/widgets/video-dialog/video-dialog.component';
import { ForgotPasswordComponent } from './components/pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { UpdateDocumentComponent } from './components/common/update-document/update-document.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { SafePipePipe } from './pipes/utils/safe-pipe.pipe';
import { NumberDecimalFixedPipe } from './pipes/utils/number-decimal-fixed.pipe';
import { TwoFactorService } from './services/twoFactor/two-factor.service';
import { PressReleaseDetailComponent } from './components/content-page/press-center/press-release-detail/press-release-detail.component';
import { RounddecimalsPipe } from './pipes/utils/rounddecimals.pipe';
import { KeysPipe } from './pipes/utils/keyvalue.pipe';
import { GetfilehandlePipe } from './pipes/utils/getfilehandle.pipe';
import { ConfirmBoxComponent } from './components/widgets/confirm-box/confirm-box.component';
import { TradingChartsComponent } from './components/widgets/trading-charts/trading-charts.component';
import { MarketDepthChartComponent } from './components/widgets/market-depth-chart/market-depth-chart.component';
import { SilverPlusComponent } from './components/pages/profile/verification/silver-plus/silver-plus.component';
import { FooterstripeComponent } from './components/common/footer/footerstripe/footerstripe.component';
import { NewsTickerComponent } from './components/widgets/news-ticker/news-ticker.component';
import { RegisterPaymentMethodComponent } from './components/common/register-payment-method/register-payment-method.component';
import { BankTransferComponent } from './components/common/register-payment-method/bank-transfer/bank-transfer.component';
import { DimCurrencyComponent } from './components/common/register-payment-method/dim-currency/dim-currency.component';
import { InitialDepositModalComponent } from './components/widgets/initial-deposit-modal/initial-deposit-modal.component';
import { UpdateProfileModalComponent } from './components/widgets/update-profile-modal/update-profile-modal.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NumberDirective } from './directives/number.directive';
import { JsonpModule } from '@angular/http';
import { ConfirmLoginComponent } from './components/widgets/confirm-login/confirm-login.component';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './components/pages/profile/account/account.component';
import { ProfileProComponent } from './components/pages/profile/profile-pro/profile-pro.component';
import { ConfirmOrderLoginComponent } from './components/widgets/confirm-order-login/confirm-order-login.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

// import { SocketService } from './services/websocket/socket.service';
// import { DocumentListComponent } from './components/document-list/document-list.component';
// import { DocumentComponent } from './components/document/document.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
// const config: SocketIoConfig = { url: 'https://hybsewebsocket.business-software.in', options: {} };


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        HomeComponent,
        HomeTickerComponent,
        RegisterSectionComponent,
        FooterComponent,
        RegistrationComponent,
        LoaderComponent,
        LoginComponent,
        MessageBoxComponent,
        ProfileComponent,
        SecurityComponent,
        SettingsComponent,
        VerificationComponent,
        BronzeComponent,
        SilverComponent,
        GoldComponent,
        GetnamePipe,
        PaginationComponent,
        DialogBoxComponent,
        ModalBoxComponent,
        PageBannerComponent,
        AboutUsComponent,
        PressCenterComponent,
        ContactFormComponent,
        PressImageComponent,
        PressContentComponent,
        DLogoComponent,
        ContactUsComponent,
        LegalComponent,
        LegalDetailContentComponent,
        FaqComponent,
        Error404Component,
        AccountActivationComponent,
        ChangeEmailComponent,
        VideoDialogComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        UpdateDocumentComponent,
        ComingSoonComponent,
        SafePipePipe,
        NumberDecimalFixedPipe,
        PressReleaseDetailComponent,
        RounddecimalsPipe,
        GetfilehandlePipe,
        ConfirmBoxComponent,
        TradingChartsComponent,
        MarketDepthChartComponent,
        SilverPlusComponent,
        FooterstripeComponent,
        NewsTickerComponent,
        RegisterPaymentMethodComponent,
        BankTransferComponent,
        DimCurrencyComponent,
        InitialDepositModalComponent,
        UpdateProfileModalComponent,
        NumberDirective,
        ConfirmLoginComponent,
        KeysPipe,
        AccountComponent,
        ProfileProComponent,
        ConfirmOrderLoginComponent,
        SafeHtmlPipe,
        // DocumentComponent,
        // DocumentListComponent

    ],

  entryComponents: [
    ModalBoxComponent,
    InitialDepositModalComponent,
    UpdateProfileModalComponent,
    VideoDialogComponent,
    ConfirmBoxComponent,
    ConfirmLoginComponent,
    BronzeComponent,
    GoldComponent,
    SilverComponent,
    SilverPlusComponent,
    ConfirmOrderLoginComponent],

  imports: [
    HttpClientModule,
    BatpRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    NgxQRCodeModule,
    NoopAnimationsModule,
    AmChartsModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatExpansionModule,
    MatCardModule,
    MatDividerModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatBottomSheetModule,
    FormsModule,
    NgxCaptchaModule,
    StoreModule.forRoot(batpReducers),
    ReactiveFormsModule,
    BrowserModule,
    PerfectScrollbarModule,
    JsonpModule,
    TooltipModule.forRoot(),
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    // SocketIoModule.forRoot(config)
  ],
  providers: [
    BatpConfig,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ApiUrlService,
    ApirequestService,
    AuthenticationGuard,
    AuthenticationOffGuard,
    ApiserviceService,
    StorageService,
    UtilityService,
    TimerService,
    LoginService,
    TwoFactorService,
    RegisterService,
    FsUploadService,
    CryptoService,
    LogoutService,
    DatumService,
  ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
