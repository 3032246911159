import { Component, OnInit, Input } from '@angular/core';
import { batp_versionNo, batp_buildNo } from '../../../config/batp.config';
import { CetTimeService } from '../../../services/cetTime/cet-time.service';
import { environment } from '../../../../environments/environment';
import { VideoDialogComponent } from '../../widgets/video-dialog/video-dialog.component';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';


@Component({
  selector: 'batp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private _cet:CetTimeService,
    private _dialog: MatDialog,
              private _sanitize: DomSanitizer,
              private _route: Router) { }
  @Input() currentRoute:string;
  versionNo:any = batp_versionNo;
  buildNo:any = batp_buildNo;

  currentYear = new Date().getFullYear();
  isMobile:boolean = false;

  ngOnInit() {
  }

}
