import {Component, OnInit, ViewChild, Input, PipeTransform, Pipe } from '@angular/core';
import { ApirequestService } from '../../../services/apirequest/apirequest.service';
import { ApiUrlService } from '../../../services/api-url/api-url.service';
import { Store, select } from '@ngrx/store';
import * as rootStore from '../../../app.reducer';
import { SessionOutService } from '../../../services/sessionOut/session-out.service';
import { MatDialog, MatSort } from '@angular/material';
import { DatumService } from 'src/app/services/datum/datum.service';
import { UtilityService } from 'src/app/services/utilities/utility.service';
import { StorageService } from 'src/app/services/localstorage/storage.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {captchaKey} from '../../../config/batp.config';
import {DomSanitizer} from '@angular/platform-browser';
import { ViewportScroller } from '@angular/common';

@Pipe({ name: "safeHtml" })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value) {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}

declare var $: any;

@Component({
  selector: 'batp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('favoriteStockSort') favoriteStockSort: MatSort;
  isMobile: boolean = this._utils.checkMobileDevice();

  constructor(private readonly viewport: ViewportScroller,
              private _req: ApirequestService,
              private _fb: FormBuilder,
              private store: Store<rootStore.State>,
              private _so: SessionOutService,
              private _datum: DatumService,
              private _dialog: MatDialog,
              private _url: ApiUrlService,
              private _route: Router,
              private _utils: UtilityService,
              private _sb: SnackbarService,
              private _ls: StorageService,
              private activatedRoute: ActivatedRoute) { }

  /*private vouchers: object = [
    {
      'title': 'Block Voucher Purchase Made Simple',
      'tagline': 'Your access to global digital asset trading.',
      'label': 'Block Voucher',
      'class': 'blockv',
      'icon' : 'block-voucher-icon.png',
      'icontext': '',
      'status': 'enabled'
    },
    {
      'title': 'Amazon Voucher Purchase Made Simple',
      'tagline': 'Redeem globally.',
      'label': 'Amazon Voucher',
      'class': 'amznv',
      'icon' : '2000px-Amazon_logo_plain.svg_-300x153.png',
      'icontext': 'Coming Soon',
      'status': 'disabled'
    },
    {
      'title': 'Cannabis Health Voucher Purchase Made Simple',
      'tagline': 'Redeem globally.',
      'label': 'Cannabis Health Voucher',
      'class': 'canbv',
      'icon' : 'wecanhealth-logo-small.png',
      'icontext': 'Coming Soon',
      'status': 'disabled'
    }
  ];*/
  private qrCodeData: any = {
      prefix: 'bitcoin:',
      address: 'n4fBmcVWfefJJCggnKMpY9omDK7dgWoyAK',
      amount: 0.1,
      label: 'BlockVoucher',
      message: 'Purchase',
  };
  private counter: { min: number, sec: number };
  private timerDisplay: { min: string, sec: string };
  private timerLevel = 'level1';
  private qrCodeElement: 'url' | 'canvas' | 'img' = 'url';
  private vouchers: any = [];
  private carouselData: any = [];
  private isBtc: false;
  private intervalId: any;
  private bankDetails: object = {
    EUR : {
      name: 'Smart Finance AG',
      bank: 'TransferWise Europe SA',
      IBAN: 'BE35967109539537',
      SWIFT: 'TRWIBEB1XXX',
      clnum: false,
      acnum: false,
      trnnum: false,
      ach: false
    },
    USD : {
      name: 'Smart Finance AG',
      bank: 'TransferWise',
      IBAN: false,
      SWIFT: 'RAIFCH22E01',
      clnum: false,
      acnum: '8310741123',
      trnnum: '026073008',
      ach: '026073150'
    },
    CHF : {
      name: 'Smart Finance AG',
      bank: 'Raiffeisenbank Seerücken',
      IBAN: 'CH8680808005328339991',
      SWIFT: 'RAIFCH22E01',
      clnum: '81401',
      acnum: false,
      trnnum: false,
      ach: false
    }
  };
  private idAffiliate: string = null;
  private idVoucher: string = null;
  preferredTheme:boolean = true;
  public showLoader: boolean = true;
  public captchaKey: string = captchaKey;
  public voucherForm: FormGroup;
  public apiSubmit: boolean = false;
  public step: number = 0;
  public selpreset:number = 0;
  public selectedVoucher:any;
  public formVal: any = {
    currency: 'EUR',
    asset: '',
    voucherVal: 50,
    voucherAmt: 50,
    email: '',
    fee: 1,
    total: 51,
    stockSlug: '',
    captchaKey: '',
    btcAmount: 0,
  };
  public currencies: any = [];
  public altCurrencies: any = [];
  public denominations: any = [];
  public voucherData: any = [];
  private emailRegx: RegExp = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}$/);
  public selectedBank: any = this.bankDetails['EUR'];
  public inputStep: number = 5;
  public inputMin: number = 10;
  public isFormError: boolean = false;
  isAuthenticated: boolean = false;
  public captchaSuccess = false;
  private formBtcCurrency: string;
  private blockFiat = false;
  private btcRate: any = {
      currency: '',
      native: '',
      rate: 0,
  };

  getAuthenticatedData() {
    this.store.pipe(select('auth')).subscribe(
      (data: any) => {
        this.isAuthenticated = data.isAuthenticated;
      }
    );
  }

  voucherFormInit() {
    this.voucherForm = this._fb.group({
      voucherValue: ['', [Validators.required]]
    });
  }

  isDisabled(status) {
    if (status === 'disabled') {
      return true;
    } else {
      return  false;
    }
  }

  setBtc(val, isBtc) {
      this.getBtcExchangeRate(val.toLowerCase(), this.formVal.currency.toLowerCase(), true);

      if (isBtc) {
          this.formBtcCurrency = 'BTC';
      } else {
          this.formBtcCurrency = val;
      }
      this.isBtc = isBtc;
  }

  calculateVoucherFee(currency) {
      if (this.formVal.currency.toLowerCase() !== currency.toLowerCase()) {
          this.calculateFee(this.formVal.btcAmount);
      } else {
          this.calculateFee(this.formVal.voucherAmt);
      }
  }

  getBtcExchangeRate(native, forex, update = false) {
      if (native !== this.btcRate.currency) {
        this.showLoader = true;
        this._req.fetchApiData(this._url.exchangeUrl + native + '/' + forex, {}, true, 'get').subscribe(
            (data: any) => {
                this.btcRate.currency = forex;
                this.btcRate.native = native;
                this.btcRate.rate = data.rate;
                this.showLoader = false;

                if (update) {
                    this.updateBtcExchangeRate();
                }
                this.calculateVoucherFee(native);
        });
      } else {
          this.updateBtcExchangeRate();
          this.calculateVoucherFee(native);
      }
  }

  updateBtcExchangeRate() {
      this.formVal.btcAmount = this.btcRate.native === 'btc' ? Math.round(((this.formVal.voucherAmt / this.btcRate.rate) * 1.03) * 100000000) / 100000000 :
          Math.round(((this.formVal.voucherAmt / this.btcRate.rate) * 1.03) * 100) / 100;
  }

  setFormCurrency(currency, asset) {
    const vArrIdx = this._utils.findIndexOfArrayInArray(this.voucherData, this.selectedVoucher, 'idVoucherConfig');
    this.btcRate = {
        currency: '',
        native: '',
        rate: 0,
    };
    this.formVal.currency = currency;
    this.formVal.asset = asset;
    this.selectedBank = this.bankDetails[currency];
    // if (currency !== 'USD' && currency !== 'CHF') {
        this.formBtcCurrency = currency;
        this.isBtc = false;
        this.blockFiat = false;
    // } else {
    //     this.blockFiat = true;
    //     this.setBtc(true);
    // }
    if (this.selectedVoucher.type === 'Primary') {
        const altCurFormatted = [];

        this.currencies.forEach((item, index) => {
            if (currency !== item.currency) {
                altCurFormatted.push({asset: item.asset, currency: item.currency});
            }
        });
        this.altCurrencies = altCurFormatted;
    }

    this.formVal.btcAmount = 0;
    if (typeof this.voucherData[vArrIdx].stockSlug === 'undefined') {
      this.formVal.stockSlug = 'Ð.' + this.formVal.currency;
    } else {
      this.formVal.stockSlug = this.voucherData[vArrIdx].stockSlug;
    }
    if (currency.toLowerCase() !== this.formVal.currency.toLowerCase()) {
        this.getBtcExchangeRate('BTC', this.formVal.currency.toLowerCase(), true);
    } else {
        this.calculateFee(this.formVal.voucherAmt);
    }
  }

  setPresetValue(value, amount) {
    if (this.selectedVoucher.status !== 'disabled') {
      this.selpreset = -1;
      const vArrIdx = this._utils.findIndexOfArrayInArray(this.voucherData, this.selectedVoucher, 'idVoucherConfig');
      this.selpreset = value;
      this.formVal.voucherVal = parseFloat(amount);
      this.formVal.voucherAmt = parseFloat(this.voucherData[vArrIdx].price) * parseFloat(amount);

      this.validateAmount(true);

      if (this.formVal.currency.toLowerCase() !== this.btcRate.native && this.btcRate.native !== '') {
          this.getBtcExchangeRate(this.btcRate.native, this.formVal.currency.toLowerCase(), true);
      } else {
          this.calculateFee(this.formVal.voucherAmt);
      }
    }
  }

  validateAmount(isPresetCAll = false) {
    this.isFormError = false;
    if (this.formVal.voucherVal < this.selectedVoucher.minOrderval) {
      this.isFormError = true;
      this._sb.openSnackBar('Minimum voucher value must be ' + this.selectedVoucher.minOrderval + '.', 5000, 'error', 'center', 'top');
    } else {
      if (this.formVal.voucherVal % 1 !== 0) {
        this.isFormError = true;
        this._sb.openSnackBar('Voucher value must not be with decimal.', 5000, 'error', 'center', 'top');
        this.formVal.voucherVal = (Math.floor(parseInt(this.formVal.voucherVal) / 1) * 1) + 1;
      }
    }

    if (!isPresetCAll) {
        const presetIndex = this.getPresetIndex(this.formVal.voucherVal);
        this.setPresetValue(presetIndex, this.formVal.voucherVal);
    }
  }

  validateEmail() {
    this.isFormError = false;

    if (!String(this.formVal.email).match(this.emailRegx) && this.step !== 0) {
      this.isFormError = true;
      this._sb.openSnackBar('Please enter a valid email address.', 5000, 'error', 'center', 'top');
    }
  }

  getPresetIndex(value) {
    let dnIndex = -1;
    this.denominations.forEach((val, index) => {
      if (parseFloat(val.value) === value) {
        dnIndex = index;
      }
    });

    return dnIndex;
  }

  getQRCodeData() {
      return this.qrCodeData.prefix + this.qrCodeData.address + '?amount=' + this.qrCodeData.amount + '&label=' + this.qrCodeData.label + '(' + this.qrCodeData.message + ')';
  }

  calculateFee(amount) {
    let fee;
    let feeRate;
    let minFee;

    amount = this.isBtc ? amount : Math.round(amount * 100) / 100;

    if (this.selectedVoucher.type === 'Primary') {
      feeRate = 0.02;
      minFee = 2;
      if (this.formBtcCurrency === 'ZAR') {
        minFee = 35;
      }
        if (this.isBtc) {
            minFee = 0.00015;
        }
    } else {
      feeRate = 0.04;
      minFee = 5;
        if (this.formBtcCurrency === 'ZAR') {
            minFee = 80;
        }
        if (this.isBtc) {
            minFee = 0.0003;
        }
    }

    fee = amount * feeRate;
    if (fee < minFee) {
      fee = minFee;
    }
    this.formVal.fee = fee;
    this.formVal.total = this.isBtc ? this.formVal.voucherAmt : amount + fee;
  }

  setSelectedVoucher(voucher) {
    this.selectedVoucher = voucher;

    const vArrIdx = this._utils.findIndexOfArrayInArray(this.voucherData, voucher, 'idVoucherConfig');
    if (vArrIdx !== false) {
      this.isBtc = false;
      this.formVal.btcAmount = 0;
      const vcurrs = this.voucherData[vArrIdx].currencies.replaceAll(' ', '');
      this.currencies = vcurrs.split(',');
      const qtTemp = this.voucherData[vArrIdx].qtyTemplate.replaceAll(' ', '');
      this.denominations = qtTemp.split(',');

      this.formVal.currency = this.currencies[0];
      this.formVal.voucherAmt = parseFloat(voucher.price) * parseFloat(this.denominations[0]);
      this.formVal.voucherVal = parseFloat(this.denominations[0]);

      this.selectedBank = this.bankDetails[this.formVal.currency];

      if (typeof this.voucherData[vArrIdx].stockSlug === 'undefined') {
        this.formVal.stockSlug = 'Ð.' + this.formVal.currency;
        this.formVal.asset = this.formVal.currency;
        this.formatCurrencies(this.voucherData[vArrIdx].type);
      } else {
        this.formVal.stockSlug = this.voucherData[vArrIdx].stockSlug;
        this.formVal.asset = this.formVal.stockSlug.split('-')[0];
        this.formatCurrencies(this.voucherData[vArrIdx].type, this.formVal.asset);
      }

      // if (this.formVal.currency !== 'USD' && this.formVal.currency !== 'CHF') {
        this.formBtcCurrency = this.formVal.currency;
        this.isBtc = false;
        this.blockFiat = false;
      // } else {
      //   this.blockFiat = true;
      //   this.setBtc(true);
      // }

      // this.getBtcExchangeRate('BTC', this.formVal.currency.toLowerCase());

      this.formatDenominations();
      this.validateAmount();
    }
  }

  formatDenominations() {
    let formatted = [];

    this.denominations.forEach((item, index) => {
      if (item >= 1000 && item <= 1000000) {
        formatted.push({value: item, label: (parseFloat(item) / 1000) + 'K'});
      } else if (item >= 1000000 && item <= 1000000000) {
        formatted.push({value: item, label: (parseFloat(item) / 1000000) + 'M'});
      } else {
        formatted.push({value: item, label: item});
      }
    });

    this.denominations = formatted;
  }

  formatCurrencies(type, pair = null) {
    const formatted = [];
    const altCurFormatted = [];

    if (type === 'Primary') {
      this.currencies.forEach((item, index) => {
        formatted.push({asset: item, currency: item});

        if (index > 0) {
            altCurFormatted.push({asset: item, currency: item});
        }
      });
    } else {
      this.currencies.forEach((item, index) => {
          if(index === 0) {
              formatted.push({asset: pair, currency: item});
          } else {
              altCurFormatted.push({asset: item, currency: item});
          }
      });
    }
    this.currencies = formatted;
    this.altCurrencies = altCurFormatted;
  }

  checkIfDisabledVoucher() {
    if (this.selectedVoucher.status === 'disabled') {
      return true;
    } else {
      return false;
    }
  }

  submitVoucherForm() {
      this.validateEmail();

    if (!this.isFormError) {
      this.step++;
      this.isFormError = false;
    }
  }

  resetForm() {
    this.step = 0;
    this.selpreset = 0;
    this.selectedVoucher = this.vouchers[0];
    this.apiSubmit = false;
    this.isFormError = false;
    this.btcRate = {
        currency: '',
        native: '',
        rate: 0,
    };
    this.setSelectedVoucher(this.vouchers[0]);
    clearInterval(this.intervalId);
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
  }

  submitToApi() {
    this.apiSubmit = true;
    const data = {
      'securityKey': '83ca5c3cfd77b1c79d0a57df3d3389466b2e61517f5965478204d7ee862b4f86'
    };
    if (!this.captchaSuccess) {
      this.isFormError = true;
      this._sb.openSnackBar('Please validate the reCAPTCHA.', 5000, 'error', 'center', 'top');
    } else {
      this.showLoader = true;
      this._req.fetchApiData(this._url.accessKeyUrl, data).subscribe(
          (data:any) => {
            const resSucc = data.data;
            let stockSlug = '';
            if (this.selectedVoucher.type === 'Primary') {
              stockSlug = this.formVal.currency;
            } else {
              stockSlug = this.formVal.stockSlug;
            }
            const isPrimary = this.selectedVoucher.type === 'Primary' ? 1 : 0;
            let couponData: any = {};
            couponData = {
              'accessKey': resSucc.accesskey,
              'email': this.formVal.email,
              'amount': this.formVal.voucherVal,
              'voucherVal': (this.formVal.currency.toLowerCase() === this.btcRate.native) || this.btcRate.native === '' ? this.formVal.voucherAmt : this.formVal.btcAmount,
              'stockSlug': stockSlug,
              'isPrimary': isPrimary,
              'currency': (this.formVal.currency.toLowerCase() === this.btcRate.native) || this.btcRate.native === '' ? this.formVal.currency : this.btcRate.native,
              'googleRecaptcha': this.formVal.captchaKey
            };

            if (this.isBtc) {
                couponData['btcAmount'] = this.formVal.btcAmount;
            }

            if (this.idAffiliate !== null) {
                couponData['idAffiliate'] = this.idAffiliate;
            }

            this._req.fetchApiData(this._url.createCouponUrl, couponData).subscribe(
                (data: any) => {
                  const resSucc2 = data.data;
                  console.log(resSucc2);
                  this.qrCodeData.address = resSucc2.btcAddress;
                  this.step++;
                },
                error => {
                  this.showLoader = false;
                },
                () => {
                    if (this.isBtc) {
                        if (typeof this.qrCodeData.address === 'undefined') {
                            this._sb.openSnackBar('Blockchain error encountered! Please try again.<br/>Site admin has been notified about this error.', 5000, 'error', 'center', 'top');
                            this.resetForm();
                        } else {
                            this.qrCodeData.message = 'Purchase_of_' + this.formVal.voucherVal + '_' + this.formVal.asset;
                            this.qrCodeData.amount = (couponData.btcAmount + this.formVal.fee).toFixed(8);
                            this.startTimer();
                        }
                    } else {
                        this._sb.openSnackBar('The voucher has been purchased.', 5000, 'success', 'center', 'top');
                        this.resetForm();
                    }

                  this.showLoader = false;
                }
            );
          },
          error => {
            this.showLoader = false;
          },
          () => {}
      );
    }
  }

  getVoucherDetails() {
    const data = {
      limit: 50,
      offset: 0
    };

    this._req.fetchApiData(this._url.vconfigListUrl, data).subscribe(
        (res: any) => {
          const response = res.data;
          const errRes = res.error;

          if (response) {
            this.voucherData = response.voucherConfigList;

            this.processVoucherData();
          }
        },
        error => {
          this.showLoader = false;
        },
        () => {
          this.showLoader = false;
        }
    );
  }

    copyAddress(input) {
        input.select();
        document.execCommand("copy");
        this._sb.openSnackBar('Wallet Address Copied to Clipboard', 2000, 'success', 'center', 'top');
    }

    startTimer() {
        this.counter = { min: 5, sec: 0 };
        this.timerDisplay = { min: '05', sec: '00' };
        this.intervalId = setInterval(() => {
            if (this.counter.sec - 1 === -1) {
                this.counter.min -= 1;
                this.counter.sec = 59;
            } else {
                this.counter.sec -= 1;
            }

            this.timerDisplay.min = '0' + this.counter.min.toString();
            this.timerDisplay.sec = this.counter.sec >= 10 ? this.counter.sec.toString() : '0' + this.counter.sec.toString();

            switch (this.counter.min) {
                case 4:
                default:
                    this.timerLevel = 'level1';
                    break;
                case 3:
                    this.timerLevel = 'level2';
                    break;
                case 2:
                    this.timerLevel = 'level3';
                    break;
                case 1:
                    this.timerLevel = 'level4';
                    break;
                case 0:
                    this.timerLevel = 'level5';
                    break;
            }

            if (this.counter.min <= 0 && this.counter.sec <= 0) {
                clearInterval(this.intervalId);
                this.counter.min = 0;
                this.counter.sec = 0;
            }
        }, 1000);
    }

    paymentConfirm() {
        this._sb.openSnackBar('The voucher has been purchased.', 5000, 'success', 'center', 'top');
        this.resetForm();
    }

  prepareCarouselData() {
      let slideIndex = 0;
      let counter = 1;

      this.carouselData.push([]);
      this.vouchers.forEach((data, index) => {
          this.carouselData[slideIndex].push(data);

          if (counter % 3 === 0 && counter < this.vouchers.length) {
              this.carouselData.push([]);
              slideIndex++;
          }
          counter++;
      });
  }

  processVoucherData() {
    let voucherConf = {
      idVoucherConfig: '',
      title: '',
      subTitle: '',
      tagline: '',
      label: '',
      class: '',
      icon : '',
      icontext: '',
      ticketBg: '',
      status: '',
      price: 0,
      minOrderval: 1,
      type: '',
      btcEnabled: '0',
    };

    this.voucherData.forEach((vobj, index) => {
      voucherConf = {
        idVoucherConfig: vobj.idVoucherConfig,
        title: vobj.title,
        subTitle: vobj.subTitle,
        tagline: vobj.tagline,
        label: vobj.label,
        class: vobj.voucherSlug,
        icon : vobj.icon,
        icontext: '',
        ticketBg: vobj.ticketBg,
        status: vobj.status,
        price: parseFloat(vobj.price),
        minOrderval: parseFloat(vobj.minOrderVal),
        type: vobj.type,
        btcEnabled: vobj.btcEnabled,
      };

      if (vobj.status === 'disabled') {
        voucherConf.icontext = 'Coming soon';
      }

      if (vobj.status !== 'disabled') {
          this.vouchers.push(voucherConf);
      }
    });

    this.prepareCarouselData();
    this.selectDefaultVoucher();
  }

  selectDefaultVoucher() {
      if (this.idVoucher === null) {
          this.setSelectedVoucher(this.vouchers[0]);
      } else {
          const defV = this._utils.findIndexOfArrayByValue(this.vouchers, 'idVoucherConfig', this.idVoucher);

          if (defV !== null) {
              this.setSelectedVoucher(this.vouchers[defV]);
          } else {
              this.setSelectedVoucher(this.vouchers[0]);

              const curIdx = this._utils.findIndexOfArrayByValue(this.currencies, 'currency', this.idVoucher);
              if (curIdx !== null) {
                  this.setFormCurrency(this.idVoucher, this.idVoucher);
              }
          }
      }
  }

  handleSuccess(event) {
    this.formVal.captchaKey = event;
    this.captchaSuccess = true;
    this.apiSubmit = false;
    this.isFormError = false;
  }

  ngOnInit() {
      this.activatedRoute.params.subscribe( param => {
          this.idAffiliate = param.affiliateId;
          this.idVoucher = param.voucherId;
      });

    this.getAuthenticatedData();
    this.getVoucherDetails();
  }

}
