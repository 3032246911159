import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from '../../app.component';
import { HomeComponent } from '../../components/pages/home/home.component';
import { RegistrationComponent } from '../../components/pages/registration/registration.component';
import { LoginComponent } from '../../components/pages/login/login.component';
import { ProfileComponent } from '../../components/pages/profile/profile.component';
import { SecurityComponent } from '../../components/pages/profile/security/security.component';
import { SettingsComponent } from '../../components/pages/profile/settings/settings.component';
import { VerificationComponent } from '../../components/pages/profile/verification/verification.component';
import { SilverComponent } from '../../components/pages/profile/verification/silver/silver.component';
import { GoldComponent } from '../../components/pages/profile/verification/gold/gold.component';
import { AboutUsComponent } from '../../components/content-page/about-us/about-us.component';
import { PressCenterComponent } from '../../components/content-page/press-center/press-center.component';
import { PressImageComponent } from '../../components/content-page/press-center/press-image/press-image.component';
import { DLogoComponent } from '../../components/content-page/press-center/d-logo/d-logo.component';
import { PressContentComponent } from '../../components/content-page/press-center/press-content/press-content.component';
import { ContactUsComponent } from '../../components/content-page/contact-us/contact-us.component';
import { LegalComponent } from '../../components/content-page/legal/legal.component';
import { LegalDetailContentComponent } from '../../components/content-page/legal/legal-detail-content/legal-detail-content.component';
import { Error404Component } from '../../components/error/error404/error404.component';
import { AuthenticationGuard } from '../../gaurds/auth/auth.guard';
import { AuthenticationOffGuard } from '../../gaurds/auth/auth-off-guard';
import { AccountActivationComponent } from '../../components/pages/account-activation/account-activation.component';
import { ChangeEmailComponent } from '../../components/pages/change-email/change-email.component';
import { ForgotPasswordComponent } from '../../components/pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../../components/pages/reset-password/reset-password.component';
import { ComingSoonComponent } from '../../components/pages/coming-soon/coming-soon.component';
import { PressReleaseDetailComponent } from 'src/app/components/content-page/press-center/press-release-detail/press-release-detail.component';
import { AccountComponent } from 'src/app/components/pages/profile/account/account.component';
import { ProfileProComponent } from 'src/app/components/pages/profile/profile-pro/profile-pro.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: ':affiliateId',
    component: HomeComponent
  },
  {
      path: 'voucher/:voucherId',
      component: HomeComponent
  },
  {
      path: ':affiliateId/voucher/:voucherId',
      component: HomeComponent
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    data: { name: 'registration' },
    canActivate: [AuthenticationOffGuard]
  },
  {
    path: 'page/about-us',
    component: AboutUsComponent,
    data: { name: 'about-us' }
  },
  {
    path: 'page/terms',
    component: ContactUsComponent,
    data: { name: 'terms' }
  },
  {
    path: 'page/privacy-policy',
    component: ComingSoonComponent,
    data: { name: 'privacy-policy' }
  },
  {
    path: 'page-not-found',
    component: Error404Component,
    data: { name: 'error' }
  },
  {
    path: '**',
    component: Error404Component,
    data: { name: 'error' }
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [
    RouterModule
  ],
  providers: []
})
export class BatpRoutingModule { }
